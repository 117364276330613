import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "react-bootstrap";

import "./Login.css";
import { IState } from "../reducers";
import { sendRequest } from "../helpers/utils";
import { Navigate } from "react-router-dom";
import type { FormProps } from "antd";
import { Button, Checkbox, Form, Input } from "antd";

export function LoginAntd() {
  const [error, setError] = useState<boolean>(false);
  const dispatch = useDispatch();

  type FieldType = {
    email?: string;
    password?: string;
    remember?: string;
  };

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    console.log("Success:", values);
    const body = {
      email: values.email,
      password: values.password,
      permanent: values.remember,
    };

    setError(false);
    await sendRequest(
      `${process.env.REACT_APP_USESSL === "true" ? "https" : "http"}://${
        process.env.REACT_APP_APIHOST
      }/login`,
      { method: "POST", body: body },
      (data) => dispatch({ type: "LOGIN_SUCCESS", value: data }),
      (error) => {
        setError(true);
        dispatch({ type: "LOGIN_FAILURE", value: error.message });
      }
    );
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {
    console.log("Failed:", errorInfo);
  };

  const isAuthenticated = useSelector(
    (state: IState) => state.user.authenticated
  );

  return !isAuthenticated ? (
    <div className="centerWrap">
      <div className="loginWrapper">
        {error ? <Alert variant="danger">Wrong login or password</Alert> : ""}
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600, width: "100%" }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item<FieldType>
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item<FieldType>
            name="remember"
            valuePropName="checked"
            wrapperCol={{ offset: 8, span: 16 }}
          >
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  ) : (
    <Navigate to="/" replace></Navigate>
  );
}
