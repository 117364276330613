import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import MapIcon from "@mui/icons-material/Map";
import LogoutIcon from "@mui/icons-material/Logout";
import { useSelector } from "react-redux";
import { ITracker } from "../../reducers/trackers";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    marginTop: "40px",
    height: "calc(100% - 40px)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme({
  components: {
    MuiToolbar: {
      styleOverrides: {
        regular: {
          minHeight: "32px",
          "@media (min-width: 600px)": {
            minHeight: "40px",
          },
        },
      },
    },
  },
});

interface IProps {
  Component: React.ReactNode;
  title?: string;
}

export const Layout: React.FC<IProps> = (props) => {
  const { Component, title } = props;
  const [open, setOpen] = React.useState(isMobile ? false : true);
  const devices = useSelector((state: any) => state.trackers) || [];
  const devicesList: ITracker[] = Object.values(devices);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex", height: "100%" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
              }}
            >
              <MenuIcon />
            </IconButton>
            <Link to="/devices">
              <IconButton
                edge="start"
                aria-label="Trackery"
                sx={{
                  color: "white",
                  marginRight: "36px",
                }}
              >
                <AutoAwesomeMotionIcon />
              </IconButton>
            </Link>
            <Link to="/map">
              <IconButton
                edge="start"
                aria-label="Mapa"
                sx={{
                  color: "white",
                  marginRight: "36px",
                }}
              >
                <MapIcon />
              </IconButton>
            </Link>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {title}
            </Typography>
            {/* <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <Link to="/map">
              <IconButton
                edge="end"
                aria-label="Logout"
                sx={{
                  color: "white",
                }}
              >
                <LogoutIcon />
              </IconButton>
            </Link>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          sx={{ height: "calc(100$-4opx)" }}
        >
          {devicesList.length ? (
            devicesList.map((device) => {
              return (
                <Card
                  sx={{
                    width: "calc(100%-20px)",
                    margin: "10px",
                    cursor: "pointer",
                    height: "70px",
                  }}
                  key={device.dev_eui}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{ bgcolor: device.color }}
                        aria-label={device.deviceName}
                      >
                        {device.deviceInitials}
                      </Avatar>
                    }
                    title={device.deviceName}
                    subheader={device.dev_eui}
                  />
                  <CardContent></CardContent>
                </Card>
              );
            })
          ) : (
            <div>No devices</div>
          )}
        </Drawer>
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            // height: "100vh",
            overflow: "auto",
            marginTop: "40px",
            height: "calc(100%-40px)",
          }}
        >
          {Component}
        </Box>
      </Box>
    </ThemeProvider>
  );
};
