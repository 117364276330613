import { LatLng } from "leaflet";
import { getAccessToken } from "../helpers/auth.helper";

enum types {
  TOKEN_REFRESH_SUCCESS = "TOKEN_REFRESH_SUCCESS",
  TOKEN_REFRESH_FAILURE = "TOKEN_REFRESH_FAILURE",
  LOGOUT = "LOGOUT",
  LOGIN = "LOGIN",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILURE = "LOGIN_FAILURE",
  SET_UPLOAD_STATUS = "SET_UPLOAD_STATUS",
  FETCH_SUCCESS = "FETCH_SUCCESS",
  FETCH_CVS = "FETCH_CVS",
  FETCH_CVS_SUCCESS = "FETCH_CVS_SUCCESS",
  FETCH_CVS_FAILURE = "FETCH_CVS_FAILURE",
  API_START = "API_START",
  API_FAILURE = "API_FAILURE",
  API_SUCCESS = "API_SUCCESS",
  PATCH_CV = "PATCH_CV",
  PATCH_CV_SUCCESS = "PATCH_CV_SUCCESS",
  PATCH_CV_FAILURE = "PATCH_CV_FAILURE",
  UPLOAD_FAILURE = "UPLOAD_FAILURE",
  UPLOAD_SUCCESS = "UPLOAD_SUCCESS",
  LOCATION_CHANGE = "@@router/LOCATION_CHANGE",
  SET_MODAL = "SET_MODAL",
  ADD_TRACKERS = "ADD_TRACKERS",
  UPDATE_TRACKER = "UPDATE_TRACKER",
  SHOW_ONLY = "SHOW_ONLY",
  WS_CONNECTED = "WS_CONNECTED",
  ONLINE = "ONLINE",
  SET_USER_SETTINGS = "SET_USER_SETTINGS",
  SET_POSITIONS = "SET_POSITIONS",
  ADD_POSITION = "ADD_POSITION",
  SET_USER = "SET_USER",
  MAP_SET_CENTER = "MAP_SET_CENTER",
}

export default types;

export const setMapCenterAction = (center: LatLng, zoom: number): any => {
  return {
    type: types.MAP_SET_CENTER,
    value: { center: { lat: center.lat, lng: center.lng }, zoom },
  };
};

export const setUploadStatusAction = (status: boolean | string): any => {
  return {
    type: types.SET_UPLOAD_STATUS,
    value: status,
  };
};

export const loginAction = (username: string, password: string): any => {
  return {
    type: types.API_START,
    fetchConfig: {
      path: process.env.REACT_APP_API_URL + "/login/",
      // headers: { Authorization: 'Bearer ' + token },
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify({ username, password }),
      success: (value: any) => {
        if (value.accessToken && value.refreshToken)
          return { type: types.LOGIN_SUCCESS, value: value };
        else
          return {
            type: types.LOGIN_FAILURE,
            value,
          };
      },
      failure: (value: any, fetchConfig: any) => {
        return {
          type: types.LOGIN_FAILURE,
          value,
          errorFetchConfig: fetchConfig,
        };
      },
    },
  };
};

export const refreshToken = (token: string): any => {
  return {
    type: types.API_START,
    fetchConfig: {
      path: process.env.REACT_APP_API_URL + "/token",
      // headers: { Authorization: 'Bearer ' + token },
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify({ token }),
      success: (value: any) => {
        return { type: types.TOKEN_REFRESH_SUCCESS, value: value };
      },
      failure: (value: any, fetchConfig: any) => {
        return {
          type: types.TOKEN_REFRESH_FAILURE,
          value,
          errorFetchConfig: fetchConfig,
        };
      },
    },
  };
};

export const updateCv = (id: number, body: any): any => {
  // const token = userToken;
  return {
    type: types.API_START,
    fetchConfig: {
      path: process.env.REACT_APP_API_URL + "/cv/" + id,
      // headers: { Authorization: 'Bearer ' + token },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAccessToken(),
      },
      method: "PATCH",
      body: JSON.stringify(body),
      success: (value: any) => {
        return { type: types.PATCH_CV_SUCCESS, value: value };
      },
      failure: (value: any, fetchConfig: any) => {
        return {
          type: types.PATCH_CV_FAILURE,
          value,
          errorFetchConfig: fetchConfig,
        };
      },
    },
  };
};

export const uploadForm = (formData: FormData): any => {
  // const token = userToken;
  return {
    type: types.API_START,
    fetchConfig: {
      path: process.env.REACT_APP_API_URL + "/upload",
      // headers: { Authorization: 'Bearer ' + token },
      method: "POST",
      body: formData,
      success: (value: any) => {
        return { type: types.UPLOAD_SUCCESS, value: value };
      },
      failure: (value: any, fetchConfig: any) => {
        return {
          type: types.UPLOAD_FAILURE,
          value,
          errorFetchConfig: fetchConfig,
        };
      },
    },
  };
};

export const fetchCvs = (): any => {
  // const token = userToken;
  return {
    type: types.API_START,
    fetchConfig: {
      path: process.env.REACT_APP_API_URL + "/cvs",
      headers: { Authorization: "Bearer " + getAccessToken() },
      method: "GET",
      // body: formData,
      success: (value: any) => {
        return { type: types.FETCH_CVS_SUCCESS, value: value };
      },
      failure: (value: any, fetchConfig: any) => {
        return {
          type: types.FETCH_CVS_FAILURE,
          value,
          errorFetchConfig: fetchConfig,
        };
      },
    },
  };
};
