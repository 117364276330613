import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { IState } from "../../reducers";

import types from "../../actions/actionTypes";
import { IPositions } from "../../reducers/positions";
import { sendRequest } from "../../helpers/utils";
import { parsePositions, parseTrackerSettings } from "../../helpers/loraUtils";
import { ITrackers } from "../../reducers/trackers";
import { Avatar, Button, Col, Form, Modal, Select, Switch } from "antd";
import style from "./SettingsModal.module.css";
import { Row } from "react-bootstrap";

export const SettingsModalAntd: React.FC = () => {
  const dispatch = useDispatch();
  const show = useSelector((state: IState) => state.modals.settingsModal);
  const trackers = useSelector((state: IState) => state.trackers);
  const userSettings: any = useSelector((state: IState) => state.userSettings);
  // const [age, setAge] = useState<number>(120);

  const handleClose = () => {
    dispatch({
      type: "SET_MODAL",
      payload: { modalId: "settingsModal", value: false },
    });
  };

  const handleChangeAge = async (value: any) => {
    const historyAge = Number(value);
    // setAge(historyAge);
    await sendRequest(
      `${process.env.REACT_APP_USESSL === "true" ? "https" : "http"}://${
        process.env.REACT_APP_APIHOST
      }/history/${historyAge * 60 * 1000}`,
      { method: "GET", useToken: true },
      (data) => {
        console.log("history data", data);
        const historyPositions: IPositions = parsePositions(data) as IPositions;
        const trackerSettings: ITrackers = parseTrackerSettings(data);

        // const trackerSettings: ITrackers = parseTrackerSettings(data);
        console.log("setting history positions");

        dispatch({ type: "SET_POSITIONS", payload: historyPositions });
        dispatch({ type: "ADD_TRACKERS", payload: trackerSettings });
      },
      () => {}
    );
  };

  const handleChange = (dev_eui: string) => {
    dispatch({ type: types.UPDATE_TRACKER, payload: { dev_eui } });
  };
  const handleSetSettings = (value: any, setting: string) => {
    // console.log(value);
    dispatch({
      type: types.SET_USER_SETTINGS,
      payload: { setting, value: !userSettings[setting] },
    });
  };

  const ageOptions = [
    { value: 5, label: "5 minút" },
    { value: 120, label: "2 hodiny" },
    { value: 240, label: "4 hodiny" },
    { value: 350, label: "5 hodin" },
    { value: 1440, label: "1 den" },
    { value: 2880, label: "2 dni" },
    { value: 7200, label: "5 dni" },
  ];

  return (
    <>
      <Modal
        title="Nastavenia"
        open={show}
        footer={[
          <Button type="primary" key="Ok" onClick={handleClose}>
            OK
          </Button>,
        ]}
        onOk={() => {}}
        onCancel={handleClose}
      >
        <strong>Zobrazit/skryt trackery</strong>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginBottom: "10px",
          }}
        >
          {Object.keys(trackers).map((dev_eui) => {
            let tracker = trackers[dev_eui];
            return (
              <Avatar
                key={dev_eui}
                style={{
                  cursor: "pointer",
                  backgroundColor: tracker.show ? tracker.color : "grey",
                }}
                className={style.avatar}
                onClick={() => handleChange(dev_eui)}
              >
                {tracker.deviceInitials}
              </Avatar>
            );
          })}
        </div>
        <Row style={{ margin: 0 }}>
          <Col span={12}>
            <strong>Zobrazit vrstvy</strong>
            <br />
            <Form.Item label="Vrstevnice">
              <Switch
                checked={userSettings.layers}
                onChange={(value) => handleSetSettings(value, "layers")}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <strong>História trasy</strong>
            <br />
            <Select
              variant="filled"
              options={ageOptions}
              defaultValue={120}
              onChange={handleChangeAge}
              style={{ width: "120px" }}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};
