import React from "react";

const DEBUG = process.env.NODE_ENV === "development";

export function decodeJWT(jwt: string) {
  const [header, payload] = jwt
    .split(".")
    .slice(0, 2)
    .map((el) => el.replace(/-/g, "+").replace(/_/g, "/"))
    .map((el) => JSON.parse(window.atob(el)));
  return { header, payload };
}

export function pluralize(word: string, value: number): string {
  if (value > 1) {
    if (word.slice(-1) !== "s") {
      return word + "s";
    }
  } else {
    if (word.slice(-1) === "s") {
      return word.slice(0, -1);
    }
  }

  return word;
}

/**
 * Returns YYYY-MM-DD string of given date
 */
export function formatDate(date: Date): string {
  return date.toISOString().slice(0, 10);
}

/**
 * Converts local TZ aware date to UTC bu using TZ offset
 *
 * Sat Mar 07 2020 00:00:00 GMT+0100 (Central European Standard Time) // "2020-03-06T23:00:00.000Z"
 * becomes
 * Sat Mar 07 2020 01:00:00 GMT+0100 (Central European Standard Time) // "2020-03-07T00:00:00.000Z"
 */
export function toUTC(localDate: Date): Date {
  const minuteOffset = localDate.getTimezoneOffset() * -1 * 60000;
  return new Date(localDate.getTime() + minuteOffset);
}

/**
 * Renders JSX element as react fragment
 */
export function renderChild(item: JSX.Element, index: number): JSX.Element {
  return <React.Fragment key={index}>{item}</React.Fragment>;
}

/**
 * Simple runtime check if 'obj' fullfills interface 'T'
 * Checks only if all 'props' are present in 'obj'.
 */
export function isInterface<T>(obj: any, props: string[]): obj is T {
  let valid = true;
  valid = valid && !!obj && typeof obj === "object";
  props.forEach((prop) => {
    valid = valid && prop in obj;
  });
  return valid;
}

export function debug(
  txt: string,
  mode: "error" | "warn" | "info" | "log" = "log",
  force = false
) {
  if (DEBUG || force) {
    console[mode](txt);
  }
}

export function getPercentage(props: {
  numbers: number[];
  dp?: number;
  showSymbol?: boolean;
}): number | string {
  const number_1 = props.numbers[0] | 0;
  const number_2 = props.numbers[1] | 0;
  let percentage = isNaN((number_1 / number_2) * 100)
    ? 0
    : (number_1 / number_2) * 100;
  if (typeof props.dp === "number") {
    percentage = Number(percentage.toFixed(props.dp));
  }
  if (props.showSymbol) {
    return `${percentage}%`;
  }
  return percentage;
}

//@TODO remove after moveing to ISO/timestamp from date string
export function getMonthName(month: string): string {
  switch (month) {
    case "01":
      return "January";
    case "02":
      return "February";
    case "03":
      return "March";
    case "04":
      return "April";
    case "05":
      return "May";
    case "06":
      return "Jun";
    case "07":
      return "July";
    case "08":
      return "August";
    case "09":
      return "September";
    case "10":
      return "October";
    case "11":
      return "November";
    default:
      return "December";
  }
}
