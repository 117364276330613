import * as React from "react";
// import { isMobile } from "react-device-detect";
// import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ITracker } from "../../reducers/trackers";
import Map from "../Map";
import style from "./mapLayoutAntd.module.css";
import L from "leaflet";
import { Avatar, Card, Flex, Layout } from "antd";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setMapCenterAction } from "../../actions/actionTypes";
import IconBar from "../IconBar";
import { use100vh } from "react-div-100vh";
const { Content, Sider } = Layout;

interface IProps {
  title?: string;
}

export const MapLayoutAntd: React.FC<IProps> = (props) => {
  const positions = useSelector((state: any) => state.positions) || [];
  const devices = useSelector((state: any) => state.trackers) || [];
  const devicesList: ITracker[] = Object.values(devices);
  // const [collapsed, setCollapsed] = React.useState(true);

  const dispatch = useDispatch();

  // const batteryIcon = (voltage: number) => {
  //   if (voltage < 3) {
  //     return faBattery0;
  //   } else if (voltage < 3300) {
  //     return faBattery2;
  //   } else if (voltage < 3800) {
  //     return faBattery3;
  //   } else if (voltage < 4000) {
  //     return faBattery4;
  //   } else {
  //     return faBattery5;
  //   }
  // };

  // const deviceCourse = (device: ITracker) => {
  //   const lastPosition =
  //     positions[device.dev_eui][positions[device.dev_eui].length - 1];
  //   const penultimatePosition =
  //     positions[device.dev_eui][positions[device.dev_eui].length - 2];
  //   return (
  //     Math.round(
  //       bearing(
  //         point([penultimatePosition.latLng[1], penultimatePosition.latLng[0]]),
  //         point([lastPosition.latLng[1], lastPosition.latLng[0]]),
  //         { final: true }
  //       )
  //     ) - 45
  //   );
  // };

  const deviceClickhandler = (device: ITracker) => {
    const center = new L.LatLng(
      positions[device.dev_eui][positions[device.dev_eui].length - 1].latLng[0],
      positions[device.dev_eui][positions[device.dev_eui].length - 1].latLng[1]
    );
    dispatch(setMapCenterAction(center, 17));
  };

  const height = use100vh();

  return (
    <Layout>
      <Sider
        width={60}
        style={{ overflow: "auto", height: height + "px" }}
        theme="light"
        className={style.sider}
      >
        <Flex
          align="space-between"
          vertical
          justify="space-between"
          style={{ overflow: "auto", height: height + "px" }}
        >
          <div className={style.devices}>
            {devicesList.length ? (
              devicesList.map((device) => {
                const customCardStyle: React.CSSProperties = {
                  margin: "5px",
                  cursor: "pointer",
                  transition: "all 0.3s",
                  height: "50px",
                  width: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                };
                const bodyCardStyle: React.CSSProperties = { padding: "3px" };
                return (
                  <Card
                    key={device.dev_eui}
                    size="small"
                    style={customCardStyle}
                    className={style.collapsedCard}
                    onClick={() => {
                      deviceClickhandler(device);
                    }}
                    styles={{ body: bodyCardStyle }}
                    hoverable
                  >
                    <Flex gap="small" align="center">
                      <Avatar
                        style={{
                          backgroundColor: device.color,
                          transition: "all 0.5s",
                        }}
                        size="large"
                        shape={"square"}
                      >
                        {device.deviceInitials}
                      </Avatar>
                    </Flex>
                  </Card>
                );
              })
            ) : (
              <div>No devices</div>
            )}
          </div>
          <div
            className={style.buttonBar}
            style={{ opacity: 1, transition: "all 0.5s" }}
          >
            <IconBar
              icons={[
                // <FontAwesomeIcon icon={faGaugeHigh} />,
                // <FontAwesomeIcon icon={faGear} />,
                <FontAwesomeIcon icon={faArrowRightFromBracket} />,
              ]}
            />
          </div>
        </Flex>
      </Sider>
      <Layout
        className={style.contentWrapper}
        style={{ height: height ? height + "px" : "100vh" }}
      >
        <Content style={{ margin: 0 }}>
          <Map />
        </Content>
      </Layout>
    </Layout>
  );
};
