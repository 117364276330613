import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../reducers";
import { Button, Form, Modal, InputRef, Input } from "antd";
import { useRef } from "react";
interface IProps {
  onSuccess: (title: string | undefined) => Promise<void>;
  onClose: () => void;
}

export const DrawingModal: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const show = useSelector((state: IState) => state.modals.drawingModal);
  const inputRef = useRef<InputRef>(null);

  const closeModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: { modalId: "drawingModal", value: false },
    });
  };

  const handleOK = async () => {
    await props.onSuccess(inputRef?.current?.input?.value);
    closeModal();
  };

  const handleClose = async () => {
    await props.onClose();
    closeModal();
  };

  return (
    <>
      <Modal
        title="Názov"
        open={show}
        footer={[
          <Button type="primary" key="Save" onClick={() => handleOK()}>
            Save
          </Button>,
        ]}
        onOk={() => handleOK()}
        onCancel={() => handleClose()}
      >
        <Form>
          <Form.Item label="Názov" name="title">
            <Input ref={inputRef} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
