import types from "../actions/actionTypes";

export interface IModal {
  [key: string]: boolean;
}
const defaultState = {
  settingsModal: false,
  drawingModal: false,
};

export const modals = (state: IModal = defaultState, action: any): IModal => {
  switch (action.type) {
    case types.SET_MODAL:
      return { ...state, [action.payload.modalId]: action.payload.value };
    default:
      return state;
  }
};
