import { LatLng } from "leaflet";
import { Dispatch } from "react";
import { sendRequest } from "./utils";

export const onDeleted = (e: any) => {
  console.log(e);
  Object.values(e.layers._layers).forEach(async (item: any) => {
    let body = {};
    if (item._latlng) {
      body = { coordinates: [item._latlng.lat, item._latlng.lng] };
    } else {
      body = {
        coordinates: item._latlngs[0].map((ll: LatLng) => {
          return [ll.lat, ll.lng];
        }),
      };
    }
    console.log("body", body);
    return await sendRequest(
      `${process.env.REACT_APP_USESSL === "true" ? "https" : "http"}://${
        process.env.REACT_APP_APIHOST
      }/area`,
      {
        method: "DELETE",
        body,
        useToken: true,
      },
      (data) => {
        console.log(data);
        return data;
      },
      () => {}
    );
  });
};

export const onEdited = (e: any, set: Dispatch<any>) => {
  console.log("EDITED", e);
  set((currentItems: any) => {
    const fetchData = async (body: any) => {
      await sendRequest(
        `${process.env.REACT_APP_USESSL === "true" ? "https" : "http"}://${
          process.env.REACT_APP_APIHOST
        }/area`,
        {
          method: "PATCH",
          body,
          useToken: true,
        },
        (data) => {
          console.log("EDIT SUCCESS", data);
        },
        () => {}
      );
    };

    let newItems = [...currentItems];
    console.log(Object.values(e.layers._layers));
    Object.values(e.layers._layers).forEach(async (item: any) => {
      const stateItem: any = currentItems.find(
        (currentItem: any) => item._leaflet_id === currentItem.leafletId
      );
      newItems = currentItems.filter((currentItem: any) => {
        return item._leaflet_id !== currentItem.leafletId;
      });
      if (stateItem) {
        let body: any = { _id: stateItem._id };
        if (item._latlng) {
          body.coordinates = [item._latlng.lat, item._latlng.lng];
          body.radius = item._mRadius;
          stateItem.coordinates = body.coordinates;
          stateItem.radius = body.radius;
        } else {
          body.coordinates = item._latlngs[0].map((ll: LatLng) => {
            return [ll.lat, ll.lng];
          });
          stateItem.coordinates = body.coordinates;
        }
        fetchData(body);
        newItems = [...newItems, stateItem];
      }
    });
    return [...newItems];
  });
};

export const onEndDrawing = async (e: any, title: string | undefined) => {
  console.log("End drawing", e);
  const feature = e.layer;
  let radius = 0;
  let locationType;
  let coordinates = [];
  if (e.layerType === "circle") {
    locationType = "Point";
    coordinates = [feature._latlng.lat, feature._latlng.lng];
    radius = feature._mRadius;
  } else {
    locationType = "Polygon";
    coordinates = feature._latlngs[0].map((loc: any) => [loc.lat, loc.lng]);
  }

  const body = {
    coordinates,
    title,
    type: "",
    locationType,
    radius,
  };
  return await sendRequest(
    `${process.env.REACT_APP_USESSL === "true" ? "https" : "http"}://${
      process.env.REACT_APP_APIHOST
    }/area`,
    { method: "POST", body: body, useToken: true },
    (data) => {
      console.log("OK", data);
      return data.result;
    },
    (error) => {
      console.log("nieco sa pokazilo", error);
    }
  );
};
