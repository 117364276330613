import "./App.css";

import { useDispatch } from "react-redux";
import { Logout } from "./components/Logout";
import { HashRouter, Routes, Route } from "react-router-dom";
import { Layout } from "./components/layouts/layout";
import { NotFound } from "./views/notFound";
// import { MapLayout } from "./components/layouts/mapLayout";
import { useEffect } from "react";
import types from "./actions/actionTypes";
import { Register } from "./components/Register";
import { Trackers } from "./views/Trackers";
import { Users } from "./views/Users";
import { MapLayoutAntd } from "./components/layouts/mapLayoutAntd";
import { ConfigProvider, theme } from "antd";
import { SettingsModalAntd } from "./components/modals/SettingsModalAntd";
import { LoginAntd } from "./components/LoginAntd";
const { defaultAlgorithm } = theme;

function App() {
  const dispatch = useDispatch();
  const updateNetwork = () => {
    console.log("network", window.navigator.onLine);
    dispatch({ type: types.ONLINE, payload: window.navigator.onLine });
  };
  useEffect(() => {
    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);
  });
  return (
    <>
      <ConfigProvider
        theme={{
          algorithm: defaultAlgorithm,
        }}
      >
        <SettingsModalAntd />
        <HashRouter basename={window.location.pathname}>
          <Routes>
            {/* <div className="fullHeight"> */}
            <Route path="/" element={<MapLayoutAntd title="Map" />} />
            <Route path="/map" element={<MapLayoutAntd title="Map" />} />
            <Route
              path="/devices"
              element={<Layout title="Trackers" Component={<Trackers />} />}
            />
            <Route
              path="/users"
              element={<Layout title="Users" Component={<Users />} />}
            />
            {/* <Route path="/share/:id" element={<Layout title="Map" />} /> */}
            {/* <Route path="/admin"  element={AdminContainer} /> */}
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<LoginAntd />} />
            <Route path="/logout" element={<Logout />} />
            {/* </div> */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </HashRouter>
      </ConfigProvider>
    </>
  );
}

export default App;
