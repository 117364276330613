import React, { ReactNode } from "react";
import style from "./IconBar.module.css";

interface IconBarProps {
  icons: ReactNode[];
}

const IconBar: React.FC<IconBarProps> = ({ icons }) => {
  return (
    <div className={style.iconBar}>
      {icons.map((icon, index) => (
        <div key={index} className={style.icon}>
          {icon}
        </div>
      ))}
    </div>
  );
};

export default IconBar;
