import { divIcon } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import ReactLeafletDriftMarker from "react-leaflet-drift-marker";
import { DevicePopup } from "./DevicePopup";
import { Polyline, Popup } from "react-leaflet";
import { useDispatch } from "react-redux";
import types from "../../actions/actionTypes";
import { IPosition } from "../../reducers/positions";
import { useRef } from "react";
import bearing from "@turf/bearing";
import { point } from "@turf/helpers";
import Vip from "../../assets/vip";

interface IProps {
  device: IPosition;
  devicePositions: IPosition[];
  iconWidth?: number;
  iconHeight?: number;
}

export const DeviceMarkerSvg: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const { device, devicePositions } = props;
  const popupref = useRef<any>(null);
  let markerPosition = device.latLng;
  const now = Date.now();
  const deviceDate = device.createdAt;
  let color = "#dedede";
  if (now - deviceDate < 600000) {
    color = device.color;
  }
  const iconWidth = props.iconWidth || 40;
  const iconHeight = props.iconHeight || 40;

  const deviceCourse = (device: IPosition) => {
    const lastPosition = devicePositions[devicePositions.length - 1];
    const penultimatePosition = devicePositions[devicePositions.length - 2];
    if (!lastPosition || !penultimatePosition) {
      return 0;
    }
    return Math.round(
      bearing(
        point([penultimatePosition.latLng[1], penultimatePosition.latLng[0]]),
        point([lastPosition.latLng[1], lastPosition.latLng[0]]),
        { final: true }
      )
    );
  };

  const hideOthers = () => {
    dispatch({ type: types.SHOW_ONLY, payload: device.dev_eui });
  };
  const iconMarkup = renderToStaticMarkup(
    <div>
      <Vip
        color={color}
        widthIcon={iconWidth}
        heightIcon={iconHeight}
        styleIcon={{
          transform: `rotate(${deviceCourse(device)}deg)`,
          transition: "transform 1s ease",
          marginLeft: "-" + iconWidth / 2 + "px",
          marginTop: "-" + iconHeight / 2 + "px",
        }}
      />
    </div>
  );
  const customMarkerIcon = divIcon({
    className: "custom-div-icon",
    html: iconMarkup,
  });

  return (
    <>
      <ReactLeafletDriftMarker
        position={markerPosition}
        duration={1000}
        icon={customMarkerIcon}
      >
        <DevicePopup
          deviceName={device.deviceName}
          temperature={device.temperature}
          speed={device.speed}
          humidity={device.humidity}
          voltage={device.voltage}
          satellites={device.satellites}
          hideOthers={hideOthers}
        />
      </ReactLeafletDriftMarker>
      <Polyline
        eventHandlers={{
          mouseover: (e: any) => {
            e.target.setStyle({ weight: 3 });
          },
          mouseout: (e: any) => {
            e.target.setStyle({ weight: 1 });
          },
        }}
        weight={1}
        positions={devicePositions.map((item) => item.latLng)}
        pathOptions={{
          color: devicePositions[devicePositions.length - 1].color,
        }}
      >
        <Popup ref={popupref}>
          <div style={{ cursor: "pointer" }} onClick={(e: any) => hideOthers()}>
            Skryt ostatne
          </div>
        </Popup>
      </Polyline>
    </>
  );
};
