import React, { useEffect, useState } from "react";

import { sendRequest } from "../helpers/utils";
import { Auth } from "../components/Auth";
import {
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  ButtonGroup,
  IconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CreditScoreIcon from "@mui/icons-material/CreditScore";

export function Users() {
  const [users, setUsers] = useState<[]>([]);
  useEffect(() => {
    const fetchUsers = async () => {
      await sendRequest(
        `${process.env.REACT_APP_USESSL === "true" ? "https" : "http"}://${
          process.env.REACT_APP_APIHOST
        }/user`,
        { method: "GET", useToken: true },
        (data) => {
          setUsers(data);
          // setDevsices(data.result.map((d: any) => ({ ...d, id: d._id })));
          console.log(data);
        },
        (e: any) => {
          console.log(e);
        }
      );
    };

    fetchUsers();
  }, []); // Or [] if effect doesn't need props or state
  const rows = users.map((user: any) => {
    return {
      email: user.email,
      devicesCount: user.devices.length,
      role: user.role,
    };
  });
  return (
    <Auth roles={["ADMIN", "SUPERADMIN"]}>
      <div style={{ margin: "10px" }}>
        <h1>Uížvatelia</h1>
        <TableContainer component={Paper}>
          <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Rola</TableCell>
                <TableCell>Trackerov</TableCell>
                <TableCell>Akcia</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.email}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.email}
                  </TableCell>
                  <TableCell>{row.role}</TableCell>
                  <TableCell>{row.devicesCount}</TableCell>
                  <TableCell>
                    <ButtonGroup
                      size="small"
                      variant="outlined"
                      aria-label="outlined button group"
                    >
                      <IconButton size="small">
                        <VisibilityIcon fontSize="small" color="primary" />
                      </IconButton>
                      <IconButton size="small">
                        <CreditScoreIcon fontSize="small" color="primary" />
                      </IconButton>
                      <IconButton size="small" color="error">
                        <DeleteForeverIcon fontSize="small" />
                      </IconButton>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Auth>
  );
}
