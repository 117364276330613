import types from "../actions/actionTypes";

export interface IMap {
  mapCenter: { lat: number; lng: number };
  mapZoom?: number;
}

export interface IPositions {
  [propName: string]: IMap[];
}

const defaultState: IMap = {
  mapCenter: {
    lat: parseFloat(process.env.REACT_APP_DEFAULT_CENTER_LAT || "0"),
    lng: parseFloat(process.env.REACT_APP_DEFAULT_CENTER_LNG || "0"),
  },
  mapZoom: parseInt(process.env.REACT_APP_DEFAULT_ZOOM || "12"),
};

export const map = (state: IMap = defaultState, action: any): IMap => {
  switch (action.type) {
    case types.MAP_SET_CENTER:
      if (
        state.mapCenter.lat === action.value.center.lat &&
        state.mapCenter.lng === action.value.center.lng &&
        state.mapZoom === action.value.zoom
      ) {
        return state;
      }
      return {
        mapCenter: action.value.center,
        mapZoom: action.value.zoom || state.mapZoom,
      };

    default:
      return state;
  }
};
