import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";

import { IState } from "../reducers";
import { Navigate } from "react-router";
import types from "../actions/actionTypes";
import "./Login.css";

export function Logout() {
  const dispatch = useDispatch();
  dispatch({ type: types.LOGOUT });
  const isAuthenticated = useSelector(
    (state: IState) => state.user.authenticated
  );

  return !isAuthenticated ? (
    <div className="centerWrap">
      <Container className="loginWrapper">Successfully logged Out!</Container>
    </div>
  ) : (
    <Navigate to="/" />
  );
}
