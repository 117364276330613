import { combineReducers } from "redux";
import { loader } from "./loader";
import { IUser, user } from "./user";
import { IModal, modals } from "./modals";
import { ITrackers, trackers } from "./trackers";
import { IUserSettings, userSettings } from "./userSettings";
import { IUserDetails, userDetails } from "./userDetails";
import { IPositions, positions } from "./positions";
import { IMap, map } from "./map";
// import { showSuccessNotification } from "./showSuccessNotification";

export interface IState {
  loader: boolean;
  user: IUser;
  userSettings: IUserSettings;
  userDetails: IUserDetails;
  modals: IModal;
  trackers: ITrackers;
  positions: IPositions;
  map: IMap;
}

export type IDispatch = (action: any) => void;

export const mainReducer = combineReducers<any>({
  loader,
  user,
  userSettings,
  userDetails,
  modals,
  trackers,
  positions,
  map,
});
