import { LatLngTuple } from "leaflet";
import types from "../actions/actionTypes";

export interface IPosition {
  latLng: LatLngTuple;
  deviceName: string;
  deviceInitials: string;
  color: string;
  createdAt: number;
  temperature: number;
  humidity: number;
  speed: number;
  voltage: number;
  satellites: number;
  dev_eui: string;
}

export interface IPositions {
  [propName: string]: IPosition[];
}

const defaultState: IPositions = {};

export const positions = (
  state: IPositions = defaultState,
  action: any
): IPositions => {
  switch (action.type) {
    case types.SET_POSITIONS:
      return action.payload;
    case types.ADD_POSITION:
      return {
        ...state,
        [action.payload.dev_eui]: updateUUID(state[action.payload.dev_eui], {
          latLng: [action.payload.coords[0], action.payload.coords[1]],
          deviceName: action.payload.deviceName,
          deviceInitials: action.payload.initials
            ? action.payload.initials
            : "unknown",
          color: action.payload.color ? action.payload.color : "#ff0000",
          temperature: action.payload.temperature,
          humidity: action.payload.humidity,
          speed: action.payload.speed,
          satellites: action.payload.satellites,
          voltage: action.payload.voltage,
          createdAt: Date.parse(action.payload.publishedAt),
          dev_eui: action.payload.dev_eui,
        }),
      };

    default:
      return state;
  }
};

const updateUUID = (uuidArray: any, newObject: any) => {
  // If uuidArray doesn't exist, create it with the newObject
  if (!uuidArray) {
    return [newObject];
  }

  // If uuidArray already exists
  let newArray;
  if (uuidArray.length >= 300) {
    // Remove the last item if the array length exceeds 300
    newArray = [...uuidArray.slice(1, uuidArray.length), newObject];
  } else {
    // Simply add the newObject to the array
    newArray = [...uuidArray, newObject];
  }

  return newArray;
};
